import React, { Component } from 'react'
import logo from './logo.svg';
import './Home.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook } from '@fortawesome/fontawesome-free-brands'
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import ReactDOM from 'react-dom';
import $ from 'jquery';

export default class Home extends Component {

	componentDidMount () {
		const $this = $(ReactDOM.findDOMNode(this));
		console.dir($this);
		$this.css('height', window.innerHeight + 'px');
	}

	render() {

		ReactGA.initialize('UA-160345627-1');
		ReactGA.pageview(window.location.pathname + window.location.search);
		ReactPixel.init('196444618278786');
		ReactPixel.pageView();

		return (
			<div className="App">
				<header className="App-header">
					<img src={logo} className="App-logo" alt="logo" />

					<p>
						<a
							className="Social-url fb"
							href="https://fb.com/gardumumeistars"
							target="_blank"
							rel="noopener noreferrer">
							<FontAwesomeIcon icon={faFacebook} /> Facebook</a>

						<a
							className="Social-url ig"
							href="https://instagram.com/gardumumeistars"
							target="_blank"
							rel="noopener noreferrer">
							<FontAwesomeIcon icon={faInstagram} /> Instagram
						</a>
					</p>
				</header>
			</div>
		)
	}
}